@import '~bootstrap/dist/css/bootstrap.css';
/* General */
html,
body {
  font-size: 15px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0);
  font-family: 'SF Pro Display Regular', sans-serif;
  line-height: 1.3;
  background-color: #ffffff;
  position: relative;
}
a {
  cursor: pointer;
  color: #35394d;
  text-decoration: none;
}
a:hover {
  color: #404b9b;
  text-decoration: none;
}
p {
  font-size: 16px;
  line-height: 1.5;
  font-family: 'SF Pro Display Regular', sans-serif;
  margin-bottom: 15px;
}
img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  outline: none;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: none;
}
table thead {
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  color: #212529;
}
.form-control {
  border-radius: 4px;
}
.table {
  border: 1px solid #e6e7ed;
}
.table > :not(caption) > * > * {
  padding: 10px 15px;
  background: none;
  border-bottom-width: 1px;
  box-shadow: none;
}
.form-switch {
  padding-left: 0;
}
.form-switch .form-check-input {
  background-color: #404b9b;
  width: 70px;
  cursor: pointer;
}
.form-switch .form-check-input {
  margin-left: 0;
}
.form-check-input {
  height: 34px;
  margin-top: 0;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input:checked {
  background-color: #404b9b;
  border-color: #404b9b;
}
table tbody tr:nth-of-type(odd),
.uk-table-striped > tr:nth-of-type(odd) {
  background-color: #fff !important;
}
table thead {
  background-color: #404b9b;
  border: 1px solid #404b9b;
  color: #fff;
}
table td {
  padding: 15px 5px 15px 25px;
  width: 1%;
  border: 1px solid #e5e5e5;
  vertical-align: middle;
}
.accordion-item {
  border-color: #e6e7ed;
  margin-bottom: 30px;
}
.accordion-item .icon {
  width: 20px;
  height: 20px;
}
.accordion-item .listItem {
  margin-bottom: 30px;
}
.accordion-item .listItem:last-child {
  margin-bottom: 0;
}
.accordion-button {
  transition: none;
  border-top: 1px solid #e6e7ed;
}
.accordion-item .listItem strong {
  margin-bottom: 15px;
}
.accordion-item .listItem p {
  display: list-item;
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 10px;
}
.accordion-button:not(.collapsed) {
  color: #35394d;
  background-color: #fff;
  box-shadow: none;
  border-bottom: 1px solid #e6e7ed;
}
.accordion-button:focus {
  border: none;
  box-shadow: none;
}
.accordion-button::after {
  background: none;
}
.accordion-button:not(.collapsed)::after {
  background: none;
}
.accordion-item:first-of-type .accordion-button {
  border-top: none;
  padding: 20px 25px;
  cursor: pointer;
}
.accordion-body {
  padding: 20px 25px;
}
table tbody tr:nth-of-type(odd),
.uk-table-striped > tr:nth-of-type(odd) {
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.modal-dialog {
  max-width: 600px;
}
.modal-header {
  padding: 20px 30px;
}
.modal-body {
  padding: 30px;
}
.btn-close:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.modal-backdrop.show {
  opacity: 0.85;
}
.form-control {
  border-color: #e6e7ed;
  border-width: 1px;
  padding: 10px 15px;
}
.form-control:focus {
  box-shadow: none;
  border-color: #ededed;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
  border: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
::placeholder,
input::placeholder,
input[type=color]::placeholder,
input[type=date]::placeholder,
input[type=datetime-local]::placeholder,
input[type=datetime]::placeholder,
input[type=email]::placeholder,
input[type=month]::placeholder,
input[type=number]::placeholder,
input[type=password]::placeholder,
input[type=search]::placeholder,
input[type=tel]::placeholder,
input[type=text]::placeholder,
input[type=time]::placeholder,
input[type=url]::placeholder,
input[type=week]::placeholder {
  color: #333;
}
/* General End */
/* Social */
.social {
  top: 35%;
  left: 0px;
  overflow: hidden;
  border-radius: 5px;
}
.social li {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  display: block;
  border-bottom: 1px solid #e6e7ed;
  border-right: 1px solid #e6e7ed;
}
.social li:first-child {
  border-top-right-radius: 5px;
  border-top: 1px solid #e6e7ed;
}
.social li:last-child {
  border-bottom-right-radius: 5px;
}
.social li:hover {
  background-color: #404b9b;
}
.social li a:hover .icon {
  background-color: #fff;
}
.social li a .icon {
  background-color: #999999;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.social li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/* Social End */
/* Header */
.nav-tabs {
  border: none;
}
.nav-tabs .active {
  border: none;
  font-weight: 500;
  color: #fff !important;
  background-color: #404b9b !important;
}
.nav-tabs .nav-link {
  padding: 12px 25px;
  border-radius: 4px;
  margin-bottom: 0;
  border: none;
  font-size: 15px;
  color: #35394d;
  position: relative;
}
header .top .left .menu .nav-tabs .nav-link:hover {
  color: #404b9b;
}
header .top .left .menu .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
  color: #404b9b;
}
header .top .right .menu ul,
li {
  list-style: none;
}
header .top .right .menu li a {
  position: relative;
}
header .bottom .menu li a {
  position: relative;
}
header .top .right .menu li a:hover .icon {
  background-color: #404b9b;
}
header .top .right .menu li a:hover .text {
  color: #404b9b;
}
header .top .right .menu li a .icon {
  width: 20px;
  height: 20px;
}
header .bottom .menu .dropdown {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #e6e7ed;
  margin-top: 15px;
}
header .bottom .menu .megaMenu {
  width: 100%;
  left: 0;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
}
header .bottom .menu .dropActive:hover .megaMenu {
  visibility: visible;
  opacity: 1;
}
header .bottom .menu .megaMenu .dropdown li a {
  line-height: 30px;
}
header .mobileMenu {
  display: none;
}
header .mobileMenu .icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}
/* Header End */
/* Main */
main .top .first .item {
  height: 365px;
}
main .top .first .item .more a {
  padding: 12px 35px;
}
main .top .first .item .more a:hover {
  color: #FFFFFF;
  background-color: #212121;
}
main .top .first .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: #404b9b;
}
main .top .first .image {
  height: 365px;
}
main .top .last {
  height: 100%;
}
main .top .last .item {
  width: 100%;
}
main .top .last .item .form-control:focus {
  box-shadow: none;
  border: none;
}
main .top .last .item .btn:hover {
  background-color: #212121;
}
main .top .last .item .btn .icon {
  width: 22px;
  height: 22px;
}
main .bottom .box {
  margin: 15px 0;
}
main .bottom .box:hover {
  background-color: #fff;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}
/* Main End */
/* Footer */
footer {
  width: 100%;
  bottom: 0;
}
/* Footer End */
/* Login */
.login .content {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}
.login .content .middle .last .remember .form-check-input {
  border-width: 1px;
  border-color: #e6e7ed;
  border-radius: 0;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-right: 8px;
}
.login .content .middle .last .remember .form-check-input:checked[type=checkbox] {
  background-color: #404b9b;
  background-size: 16px;
  border-width: 0;
  width: 20px;
  height: 20px;
}
.login .content .middle .last .remember .form-check-label {
  cursor: pointer;
}
.login .content .middle .last .btn {
  width: 100%;
  color: #ffffff;
}
.login .content .middle .last .btn:hover {
  background-color: #212121;
}
.login .content .middle .last .forget .btn {
  color: #212121;
}
.login .content .middle .last .forget .btn:hover {
  background-color: #f3f3f6;
}
.login .content .bottom .socialLogin .top .title h2 {
  padding: 0 43px;
}
.login .content .bottom .socialLogin .top .title h2:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #e6e7ed;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.login .content .bottom .socialLogin .top .title h2:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #e6e7ed;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.login .content .bottom .socialLogin .button a {
  height: 40px;
  width: 100%;
}
.login .content .bottom .socialLogin .button a:hover {
  opacity: 0.85;
}
.login .content .bottom .socialLogin .button a.tw {
  background-color: #33ccff;
}
.login .content .bottom .socialLogin .button a.fb {
  background-color: #db4437;
}
.login .content .bottom .socialLogin .button a.gg {
  background-color: #4867aa;
}
.login .content .bottom .socialLogin .button a .icon {
  width: 16px;
  height: 16px;
}
/* Login End */
/* Location */
.breadcrumb {
  justify-content: center;
}
.breadcrumb .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb .breadcrumb-item {
  color: #fff;
  text-shadow: 0px 2px 4px rgba(101, 101, 101, 0.5);
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}
.location .bottom .filter .view li {
  height: 35px;
  white-space: nowrap;
}
.location .bottom .filter .view li button.active {
  opacity: 1;
}
.location .bottom .filter .view li button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  opacity: 0.7;
}
.location .bottom .filter .view li button a {
  font-family: 'SF Pro Display Regular', sans-serif;
}
.location .bottom .filter .view li .dropdown-menu {
  border-color: #ededed;
  font-size: 14px;
  border-radius: 2px;
  padding: 10px 0 10px 0;
}
.location .bottom .filter .view li .dropdown li {
  height: auto;
}
.location .bottom .filter .view li a.btn {
  border-radius: 2px;
  white-space: nowrap;
}
.location .bottom .filter .view li a .icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.location .bottom .filter .view li a .dropdownIcon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-top: 2px;
}
.location .bottom .filter .view li a .nearbyIcon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
.location .item .content img {
  width: 100%;
}
.location .listItems {
  margin-bottom: 30px;
  border: 1px solid #e6e7ed;
  border-radius: 5px;
}
.location .listItems:last-child {
  margin-bottom: 0;
}
.location .listItems .image img {
  width: 100%;
  height: 205px;
  object-fit: cover;
  object-position: center;
}
.location .singleItem .content img {
  width: 100%;
}
/* Location End */
/* Page */
.about .feachuredImage {
  background-size: cover;
  background-position: center;
}
.feachuredImage h1.title {
  text-shadow: 0px 2px 4px rgba(101, 101, 101, 0.5);
}
.about .desciption p {
  font-size: 16px;
  color: #35394d;
}
.about .desciption p:last-child {
  margin-bottom: 0;
}
.about .list {
  margin-top: 25px;
}
.about .list p {
  font-size: 15px;
  color: #35394d;
}
.contact .send .btn {
  margin: 0 auto;
}
.contact .send .btn:hover {
  color: #fff;
  background-color: #212121;
}
.navigation a {
  color: #35394d;
  font-family: 'SF Pro Display Medium', sans-serif;
  margin: 0 20px;
}
.navigation a.active {
  color: #404b9b;
}
.navigation a:hover {
  color: #404b9b;
}
.sidebar {
  overflow: hidden;
}
.sidebar .list {
  margin-bottom: 20px;
}
.sidebar .list:last-child {
  margin-bottom: 0;
}
.sidebar .list .title span.l {
  top: 12px;
  width: 100%;
  height: 1px;
  background-color: #e6e7ed;
}
.sidebar .list .dropdown li {
  padding-bottom: 10px;
  font-size: 16px;
}
.sidebar .list .dropdown li:last-child {
  margin-bottom: 0;
}
.page .desciption p {
  font-size: 15px;
}
.page .desciption p b,
strong {
  display: block;
  font-weight: normal;
  font-family: 'SF Pro Display Medium', sans-serif;
}
.page .desciption .item {
  margin-bottom: 25px;
}
.page .desciption .item:last-child {
  margin-bottom: 0;
}
.box:hover {
  background-color: #F9F9FB;
}
.box:hover .icon {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.box .icon {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.box .icon .image {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
.faq .icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}
.faq a {
  margin-right: 15px;
  margin-bottom: 20px;
}
.faq a:hover {
  background-color: #404b9b;
  color: #fff;
}
.faq a:hover .icon {
  background-color: #ffffff;
}
/* Page End */
/* Account */
.account .items {
  border-bottom: 1px solid #e6e7ed;
}
.account .items:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.sendBtn:hover {
  color: #fff;
  background-color: #212529;
}
.account .btn:hover {
  color: #fff;
}
.account .form-select {
  height: 45px;
  border-color: #e6e7ed;
}
.account .phone {
  margin-bottom: -15px;
}
.account .form-select:focus {
  box-shadow: none;
}
.account .info .name li span {
  font-family: 'SF Pro Display Medium', sans-serif;
  border-radius: 99px;
  height: 100px;
  width: 100px;
  display: block;
  line-height: 100px;
  background: #404b9b;
  font-size: 36px;
  color: #fff;
  margin: 0 auto;
}
.account .list li {
  width: 100%;
}
.account .address p {
  font-family: 'SF Pro Display Medium', sans-serif;
  line-height: 1.3;
  margin-bottom: 0;
}
.anket .form-select {
  width: 100%;
  border-color: #e6e7ed;
  height: 45px;
}
/* Account End */
/* Send */
.order .info {
  margin-bottom: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.order .info h5 {
  flex: 0 0 50%;
  font-size: 15px;
  text-align: right;
  margin: 0 5px;
  padding: 0 5px;
}
.order .info h5:nth-of-type(2n) {
  text-align: left;
}
.payment .box {
  width: 100%;
  cursor: pointer;
}
.pay a.last:hover {
  color: #35394d !important;
}
.payment .active {
  border-width: 2px;
  border-color: #404b9b;
}
.sidebar {
  height: 100%;
}
.sidebar .pay a:hover {
  color: #fff;
}
.step_3 .list {
  margin-bottom: 30px;
}
.step_3 .list:last-child {
  margin-bottom: 0;
}
.send .box {
  cursor: pointer;
}
.send .box .active {
  border-color: #404b9b;
}
.send .box .borDer {
  border-width: 2px;
}
.send .box:hover .borDer {
  background-color: #fff;
  border-color: #404b9b;
}
.send .checkBox .form-check-input {
  cursor: pointer;
  border: 2px solid #e6e7ed;
  width: 100%;
  height: 150px;
  border-radius: 5px;
}
.send .form-check-input:focus {
  box-shadow: none;
}
.send .form-check-input:checked[type=checkbox] {
  border-color: #404b9b;
}
.fade:not(.show) {
  display: none;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
}
.send .form-select {
  cursor: pointer;
  background-position: right 1.1rem center;
  font-size: 18px;
  padding-left: 15px;
}
.send .form-floating > .form-select {
  height: auto;
  padding-top: 30px;
  padding-bottom: 10px;
  border: 1px solid #e6e7ed;
}
.send label {
  opacity: 0.85;
  color: #35394d;
  font-size: 16px;
  padding-left: 15px;
}
.send .form-select:focus {
  box-shadow: none;
  border-color: #e6e7ed;
}
.step_3 .form-check-input {
  border-width: 1px;
  border-color: #e6e7ed;
  border-radius: 0;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-right: 8px;
}
.step_3 .form-check-input:checked[type=checkbox] {
  background-color: #404b9b;
  background-size: 16px;
  border-width: 0;
  width: 20px;
  height: 20px;
}
.step_3 label {
  opacity: 1;
  font-size: 20px;
  padding-left: 5px;
  cursor: pointer;
}
/* Send End */
/* Mobile */
@media (min-width: 1900px) {
  header .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1672px;
  }
  .page .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1672px;
  }
}
@media (max-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
  main .top .first .item {
    height: 275px;
  }
  main .top .first .image {
    height: 275px;
  }
  main .top .first .item .desciption p {
    display: none;
  }
}
@media (max-width: 1199px) {
  .social {
    display: none;
  }
  main .top .first .item {
    height: 225px;
  }
  main .top .first .image {
    height: 225px;
  }
  .mobileView {
    margin-bottom: 25px;
  }
}
@media (max-width: 992px) {
  header .mobileMenu {
    display: block;
  }
  header .top .left .menu {
    display: none !important;
  }
  header .top .right .menu {
    display: none;
  }
  header .bottom {
    display: none;
  }
  main .top .first {
    border: none;
    display: none;
  }
  main .last br {
    display: none;
  }
  footer .menu {
    display: none;
  }
}
@media (max-width: 768px) {
  .navigation {
    display: none !important;
  }
  .breadcrumb {
    display: none;
  }
  footer .top {
    display: none !important;
  }
  footer .bottom {
    border: none;
    padding-top: 0;
  }
  .location .bottom .filter .time {
    margin-top: 25px;
  }
  .location .listItems h3 {
    margin-top: 25px;
  }
  .step_1 .first .row > * {
    width: 50%;
  }
  .step_1 .last .row > * {
    width: 50%;
  }
  .sidebar .paddingLeft50 {
    padding-left: 0;
  }
  .account .phone {
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  main .bottom .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/* Mobile End */
/* Font */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display Light';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/SF-Pro-Display-Light.otf');
}
@font-face {
  font-family: 'SF Pro Display Regular';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/SF-Pro-Display-Regular.otf');
}
@font-face {
  font-family: 'SF Pro Display Medium';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/SF-Pro-Display-Medium.otf');
}
@font-face {
  font-family: 'SF Pro Display Bold';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/SF-Pro-Display-Bold.otf');
}
@font-face {
  font-family: 'SF Pro Display Black';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/SF-Pro-Display-Black.otf');
}
.fontLight {
  font-family: 'SF Pro Display Light', sans-serif;
}
.fontRegular {
  font-family: 'SF Pro Display Regular', sans-serif;
  font-weight: 400;
}
.fontMedium {
  font-family: 'SF Pro Display Medium', sans-serif;
}
.fontBold {
  font-family: 'SF Pro Display Bold', sans-serif;
}
.fontBlack {
  font-family: 'SF Pro Display Black', sans-serif;
}
.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.font16 {
  font-size: 16px;
}
.font17 {
  font-size: 17px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}
.font24 {
  font-size: 24px;
}
.font26 {
  font-size: 26px;
}
.font28 {
  font-size: 28px;
}
.font30 {
  font-size: 30px;
}
.font32 {
  font-size: 32px;
}
/* Font End */
/* Hover */
.color-hover {
  -webkit-transition: color 0.9s ease;
  /* Saf3.2+, Chrome */
  -moz-transition: color 0.9s ease;
  /* FF4+ */
  -ms-transition: color 0.9s ease;
  /* IE10 */
  -o-transition: color 0.9s ease;
  /* Opera 10.5+ */
  transition: color 0.9s ease;
}
.height-hover {
  -webkit-transition: all 0.9s ease;
  /* Saf3.2+, Chrome */
  -moz-transition: all 0.9s ease;
  /* FF4+ */
  -ms-transition: all 0.9s ease;
  /* IE10 */
  -o-transition: all 0.9s ease;
  /* Opera 10.5+ */
  transition: all 0.9s ease;
}
.background-hover {
  -webkit-transition: opacity 500ms ease-out 0.1s, transform 500ms cubic-bezier(0, 0, 0.001, 1) 0.1s;
  /* Saf3.2+, Chrome */
  -moz-transition: opacity 500ms ease-out 0.1s, transform 500ms cubic-bezier(0, 0, 0.001, 1) 0.1s;
  /* FF4+ */
  -ms-transition: opacity 500ms ease-out 0.1s, transform 500ms cubic-bezier(0, 0, 0.001, 1) 0.1s;
  /* IE10 */
  -o-transition: opacity 500ms ease-out 0.1s, transform 500ms cubic-bezier(0, 0, 0.001, 1) 0.1s;
  /* Opera 10.5+ */
  transition: opacity 500ms ease-out 0.1s, transform 500ms cubic-bezier(0, 0, 0.001, 1) 0.1s;
}
.opacity-hover {
  -webkit-transition: opacity 1s ease;
  /* Saf3.2+, Chrome */
  -moz-transition: opacity 1s ease;
  /* FF4+ */
  -ms-transition: opacity 1s ease;
  /* IE10 */
  -o-transition: opacity 1s ease;
  /* Opera 10.5+ */
  transition: opacity 1s ease;
}
.all-hover {
  -webkit-transition: all 0.3s ease;
  /* Saf3.2+, Chrome */
  -moz-transition: all 0.3s ease;
  /* FF4+ */
  -ms-transition: all 0.3s ease;
  /* IE10 */
  -o-transition: all 0.3s ease;
  /* Opera 10.5+ */
  transition: all 0.3s ease;
}
.top-hover {
  -webkit-transition: top 0.7s ease;
  /* Saf3.2+, Chrome */
  -moz-transition: top 0.7s ease;
  /* FF4+ */
  -ms-transition: top 0.7s ease;
  /* IE10 */
  -o-transition: top 0.7s ease;
  /* Opera 10.5+ */
  transition: top 0.7s ease;
}
.bottom-hover {
  -webkit-transition: bottom 0.9s ease;
  /* Saf3.2+, Chrome */
  -moz-transition: bottom 0.9s ease;
  /* FF4+ */
  -ms-transition: bottom 0.9s ease;
  /* IE10 */
  -o-transition: bottom 0.9s ease;
  /* Opera 10.5+ */
  transition: bottom 0.9s ease;
}
/* Hover End */
/* Zindex */
.zIndexSmall {
  z-index: 11;
}
.zIndexMedium {
  z-index: 101;
}
.zIndexLarge {
  z-index: 1001;
}
/* Zindex End */
/* Border */
.borderNone {
  border: none;
}
.borDer {
  border: 1px solid #e6e7ed;
}
.borderTop {
  border-top: 1px solid #e6e7ed;
}
.borderBottom {
  border-bottom: 1px solid #e6e7ed;
}
.borderLeft {
  border-Left: 1px solid #e6e7ed;
}
.borderRight {
  border-right: 1px solid #e6e7ed;
}
.borderRadiusNone {
  border-radius: 0;
}
.borderRadiusTiny {
  border-radius: 2px;
}
.borderRadiusSmall {
  border-radius: 4px;
}
.borderRadiusMedium {
  border-radius: 15px;
}
.borderRadiuslarge {
  border-radius: 50px;
}
/* Border End */
/* Grayscale */
.grayscale {
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
}
.boxShadowSmall {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
/* Grayscale End */
/* Color */
.textLight {
  color: #ffffff;
}
.textMuted {
  color: #666666;
}
.textSecondary {
  color: #35394d;
}
.textPrimary {
  color: #404b9b;
}
.textDark {
  color: #212121;
}
/* Color End */
/* Background */
.coverBackgroundSize {
  background-size: cover;
}
.coverBackgroundContain {
  background-size: contain;
}
.coverBackgroundPosition {
  background-position: center;
}
.defaultBackgroundColor {
  background-color: #ffffff;
}
.lightBackgroundColor {
  background-color: #f3f3f6;
}
.secondaryBackgroundColor {
  background-color: #35394d;
}
.primaryBackgroundColor {
  background-color: #404b9b;
}
/* Background End */
/* Padding */
.padding0 {
  padding: 0;
}
.padding5 {
  padding: 5px;
}
.padding10 {
  padding: 15px;
}
.padding15 {
  padding: 15px;
}
.padding20 {
  padding: 20px;
}
.padding25 {
  padding: 25px;
}
.padding30 {
  padding: 30px;
}
.padding35 {
  padding: 35px;
}
.padding40 {
  padding: 40px;
}
.padding45 {
  padding: 45px;
}
.padding50 {
  padding: 50px;
}
/* Padding End */
/* Padding Top */
.paddingTop5 {
  padding-top: 5px;
}
.paddingTop10 {
  padding-top: 10px;
}
.paddingTop15 {
  padding-top: 15px;
}
.paddingTop20 {
  padding-top: 20px;
}
.paddingTop25 {
  padding-top: 25px;
}
.paddingTop30 {
  padding-top: 30px;
}
.paddingTop35 {
  padding-top: 35px;
}
.paddingTop40 {
  padding-top: 40px;
}
.paddingTop45 {
  padding-top: 45px;
}
.paddingTop50 {
  padding-top: 50px;
}
.paddingTop70 {
  padding-top: 70px;
}
.paddingTop100 {
  padding-top: 100px;
}
/* Padding Top End */
/* Padding Bottom */
.paddingBottom5 {
  padding-bottom: 5px;
}
.paddingBottom10 {
  padding-bottom: 10px;
}
.paddingBottom15 {
  padding-bottom: 15px;
}
.paddingBottom20 {
  padding-bottom: 20px;
}
.paddingBottom25 {
  padding-bottom: 25px;
}
.paddingBottom30 {
  padding-bottom: 30px;
}
.paddingBottom35 {
  padding-bottom: 35px;
}
.paddingBottom40 {
  padding-bottom: 40px;
}
.paddingBottom45 {
  padding-bottom: 45px;
}
.paddingBottom50 {
  padding-bottom: 50px;
}
.paddingBottom70 {
  padding-bottom: 70px;
}
.paddingBottom100 {
  padding-bottom: 100px;
}
/* Padding Bottom End */
/* Padding Left */
.paddingLeft5 {
  padding-left: 5px;
}
.paddingLeft10 {
  padding-left: 10px;
}
.paddingLeft15 {
  padding-left: 15px;
}
.paddingLeft20 {
  padding-left: 20px;
}
.paddingLeft25 {
  padding-left: 25px;
}
.paddingLeft30 {
  padding-left: 30px;
}
.paddingLeft35 {
  padding-left: 35px;
}
.paddingLeft40 {
  padding-left: 40px;
}
.paddingLeft45 {
  padding-left: 45px;
}
.paddingLeft50 {
  padding-left: 50px;
}
.paddingLeft70 {
  padding-left: 70px;
}
.paddingLeft100 {
  padding-left: 100px;
}
/* Padding Left End */
/* Padding Right */
.paddingRight5 {
  padding-right: 5px;
}
.paddingRight10 {
  padding-right: 10px;
}
.paddingRight15 {
  padding-right: 15px;
}
.paddingRight20 {
  padding-right: 20px;
}
.paddingRight25 {
  padding-right: 25px;
}
.paddingRight30 {
  padding-right: 30px;
}
.paddingRight35 {
  padding-right: 35px;
}
.paddingRight40 {
  padding-right: 40px;
}
.paddingRight45 {
  padding-right: 45px;
}
.paddingRight50 {
  padding-right: 50px;
}
.paddingRight70 {
  padding-right: 70px;
}
.paddingRight100 {
  padding-right: 100px;
}
/* Padding Right End */
/* margin */
.margin0 {
  margin: 0;
}
.margin5 {
  margin: 5px;
}
.margin10 {
  margin: 15px;
}
.margin15 {
  margin: 15px;
}
.margin20 {
  margin: 20px;
}
.margin25 {
  margin: 25px;
}
.margin30 {
  margin: 30px;
}
.margin35 {
  margin: 35px;
}
.margin40 {
  margin: 40px;
}
.margin45 {
  margin: 45px;
}
.margin50 {
  margin: 50px;
}
/* margin End */
/* margin Top */
.marginTop5 {
  margin-top: 5px;
}
.marginTop10 {
  margin-top: 10px;
}
.marginTop15 {
  margin-top: 15px;
}
.marginTop20 {
  margin-top: 20px;
}
.marginTop25 {
  margin-top: 25px;
}
.marginTop30 {
  margin-top: 30px;
}
.marginTop35 {
  margin-top: 35px;
}
.marginTop40 {
  margin-top: 40px;
}
.marginTop45 {
  margin-top: 45px;
}
.marginTop50 {
  margin-top: 50px;
}
.marginTop70 {
  margin-top: 70px;
}
.marginTop100 {
  margin-top: 100px;
}
/* margin Top End */
/* margin Bottom */
.marginBottom5 {
  margin-bottom: 5px;
}
.marginBottom10 {
  margin-bottom: 10px;
}
.marginBottom15 {
  margin-bottom: 15px;
}
.marginBottom20 {
  margin-bottom: 20px;
}
.marginBottom25 {
  margin-bottom: 25px;
}
.marginBottom30 {
  margin-bottom: 30px;
}
.marginBottom35 {
  margin-bottom: 35px;
}
.marginBottom40 {
  margin-bottom: 40px;
}
.marginBottom45 {
  margin-bottom: 45px;
}
.marginBottom50 {
  margin-bottom: 50px;
}
.marginBottom70 {
  margin-bottom: 70px;
}
.marginBottom100 {
  margin-bottom: 100px;
}
/* margin Bottom End */
/* margin Left */
.marginLeft5 {
  margin-left: 5px;
}
.marginLeft10 {
  margin-left: 10px;
}
.marginLeft15 {
  margin-left: 15px;
}
.marginLeft20 {
  margin-left: 20px;
}
.marginLeft25 {
  margin-left: 25px;
}
.marginLeft30 {
  margin-left: 30px;
}
.marginLeft35 {
  margin-left: 35px;
}
.marginLeft40 {
  margin-left: 40px;
}
.marginLeft45 {
  margin-left: 45px;
}
.marginLeft50 {
  margin-left: 50px;
}
.marginLeft70 {
  margin-left: 70px;
}
.marginLeft100 {
  margin-left: 100px;
}
/* margin Left End */
/* margin Right */
.marginRight5 {
  margin-right: 5px;
}
.marginRight10 {
  margin-right: 10px;
}
.marginRight15 {
  margin-right: 15px;
}
.marginRight20 {
  margin-right: 20px;
}
.marginRight25 {
  margin-right: 25px;
}
.marginRight30 {
  margin-right: 30px;
}
.marginRight35 {
  margin-right: 35px;
}
.marginRight40 {
  margin-right: 40px;
}
.marginRight45 {
  margin-right: 45px;
}
.marginRight50 {
  margin-right: 50px;
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight100 {
  margin-right: 100px;
}
/* margin Right End */
.vertical {
  vertical-align: middle;
}
.alignCenter {
  text-align: center;
}
.displayFlex {
  display: flex;
}
.justifyContentEnd {
  justify-content: end;
}
.upperCase {
  text-transform: uppercase;
}
.datePicker input {
  border-radius: 5px;
  color: #35394d;
  font-family: 'SF Pro Display Light', sans-serif;
}
.datePicker input {
  height: 45px;
  border: 1px solid #e6e7ed;
  width: 100%;
  padding: 0 15px 0 15px;
}
.datePicker input:focus-visible {
  outline: none;
}
.form-select:focus {
  border-color: #e6e7ed;
  outline: none;
  box-shadow: none;
}
.btn .btnItem a {
  color: white;
}
.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-button .spinner-border {
  width: 20px;
  height: 20px;
}
.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.flex-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex!important;
  -webkit-box-orient: vertical!important;
  -webkit-box-direction: normal!important;
  -ms-flex-direction: column!important;
  flex-direction: column!important;
  -webkit-box-pack: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
  -webkit-box-align: center!important;
  -ms-flex-align: center!important;
  align-items: center!important;
  cursor: pointer;
  margin: auto;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  font-size: 12px;
}
.payment-image {
  width: 60px;
}
.payment-modal {
  width: 400px;
}
.anket-image {
  width: 100%;
  margin-top: 15px;
  height: 130px;
}
